import React from 'react';

const PricePage = () => {
  return (
    <>
      <div className="section">
        Всю необходимую информацию по подключению и оплате вы можете найти в телеграм боте <a href="https://t.me/haddan_jc_bot" target="_blank" rel="noopener noreferrer">@haddan_jc_bot</a>. После подключения вам будет доступен бесплатный период несколько дней, чтобы вы могли полноценно опробовать весь функционал
       </div>
    </>
  );
};

export default PricePage;
